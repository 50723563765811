import React from "react"

import {MotionConfig} from 'framer-motion'

import Seo from "../components/layout/Seo"





// markup
const PillarPage = () => {



  return (
    <MotionConfig transition={{duration:0.75, type:"tween"}}>
    <article className="defaultArticle">
      <Seo title="Privacy Notice" description="Pygmalion Capital Privacy Notice" />
      <section>
      <h1>Disclosure Under Pillar 3 of the Capital Requirements Directive</h1>
      <p>The Capital Requirements Directive (&lsquo;<strong>CRD</strong>&rsquo;) (Directive 2013/36/EU) of the European Union established a revised regulatory capital framework across Europe governing the amount and nature of capital credit institutions and investment firms must maintain.</p>
      <p>In the United Kingdom, the CRD has been implemented by the Financial Conduct Authority (&lsquo;<strong>FCA</strong>&rsquo;) in its regulations through the General Prudential Sourcebook (&lsquo;<strong>GENPRU</strong>&rsquo;), the Prudential Sourcebook for Banks, Building Societies and Investment Firms (&lsquo;<strong>BIPRU</strong>&rsquo;) and The Interim Prudential Sourcebook for Investment Business (&ldquo;<strong>IPRU (INV)</strong>&rdquo;).</p>
      <p>The CRD consists of three &lsquo;Pillars&rsquo;:</p>
      <ul>
      <li>Pillar 1 sets out the minimum capital amount that meets the Firm&rsquo;s credit, market and operational risk capital requirement;</li>
      <li>Pillar 2 requires the Firm to assess whether its capital reserves, processes, strategies and systems are adequate to meet Pillar 1 requirements and further determine whether it should apply additional capital, processes, strategies or systems to cover any other risks that it may be exposed to; and</li>
      <li>Pillar 3 requires disclosure of specified information about the underlying risk management controls and capital position to encourage market discipline.</li>
      </ul>
      <p>The rules in BIPRU 11 set out the provision for Pillar 3 disclosure. This document is designed to meet the Firm&rsquo;s Pillar 3 obligations.</p>
      <p>The Pillar 3 disclosure document has been prepared by Pygmalion Capital Advisers LLP (&lsquo;<strong>the Firm</strong>&rsquo;) and is verified by senior management. Unless otherwise stated, all figures are as at the last financial year-end.</p>
      <p>Pillar 3 disclosures will be issued on an annual basis after the year end and published with the annual accounts.</p>
      <p>We are permitted to omit required disclosures if we believe that the information is immaterial such that omission would be unlikely to change or influence the decision of a reader relying on that information for the purpose of making economic decisions about the Firm.</p>
      <p>In addition, we may omit required disclosures where we believe that the information is regarded as proprietary or confidential. In our view, proprietary information is that which, if it were shared, would undermine our competitive position. Information is considered to be confidential where there are obligations binding us to confidentiality with our customers, suppliers and counterparties.</p>
      <p>We have made no omissions on the grounds that it is immaterial, proprietary or confidential.</p>
      <h2>Scope and application of the requirements</h2>
      <p>The Firm is authorised and regulated by the FCA and as such is subject to minimum regulatory capital requirements. The Firm is categorised as a BIPRU Firm by the FCA for capital purposes.</p>
      <p>It is an agency investment management firm and as such has no trading book exposures.</p>
      <p>The Firm is a member of a group and so is required to prepare consolidated reporting for prudential purposes. Although part of a group, the Firm is managed on a &ldquo;stand-alone&rdquo; basis for liquidity purposes and we do not foresee any impediments to the prompt transfer of capital between group entities should the need arise. There are no differences in the basis of consolidation for accounting and prudential purposes.</p>
      <h2>Governance arrangements, the management body and competence</h2>
      <p>The Senior Management Team (&ldquo;<strong>SMT</strong>&rdquo;) meet on a regular basis and at least quarterly. Such meetings have a formal agenda which countenances enterprise-wide issues and the risk appetite of the business. The meetings demonstrate how the SMT oversees and is accountable for the implementation of governance arrangements and ensures the effective and prudent management of the <a href="http://www.fshandbook.info/FS/glossary-html/handbook/Glossary/F?definition=G430">firm</a>, with due consideration to the appropriate and proportionate segregation of duties and the prevention of conflicts of interest.</p>
      <p>The Firm considers that appropriate policies are in place to ensure the fitness and properness of all staff, including the members of the SMT. All members of the SMT are experienced industry professionals, and any senior appointments are subject to the approval of the management body with due consideration to the reputation, fitness and experience of the candidate as well as the long-term strategic goals of the business.</p>
      <p>All members of the SMT are full time and have disclosed any outside business interests.</p>
      <p>Initial and ongoing assessments of the competence of staff are conducted, and all Senior Managers and Certification Staff are required to attest to their ongoing compliance with the fitness and properness obligations as set out in the Senior Managers and Certification Regime.</p>
      <p>On an ongoing basis, all staff including the SMT undergo training on a variety of regulatory topics.</p>
      <h2>Risk management</h2>
      <p>The Firm has established a risk management process in order to ensure that it has effective systems and controls in place to identify, monitor and manage risks arising in the business. The risk management process is overseen by the Chief Operating Officer and the Compliance Officer with the SMT taking overall responsibility for this process and the fundamental risk appetite of the firm. The SMT has responsibility for the implementation and enforcement of the Firm&rsquo;s risk principles.</p>
      <p>The SMT meet on a regular basis and discuss current projections for profitability, cash flow, regulatory capital management, business planning and risk management. The SMT engage in the Firm&rsquo;s risks though a framework of policy and procedures having regard to the relevant laws, standards, principles and rules (including FCA principles and rules) with the aim to operate a defined and transparent risk management framework. These policies and procedures are updated as required.</p>
      <p>The SMT has identified that business, operational, market and credit are the main areas of risk to which the Firm is exposed. Annually the SMT formally review their risks, controls and other risk mitigation arrangements and assess their effectiveness.</p>
      <p>A formal update on operational matters is provided to the SMT on a regular basis. Management accounts demonstrating continued adequacy of the firm&rsquo;s regulatory capital are reviewed on a regular basis.</p>
      <p>Appropriate action is taken where risks are identified which fall outside of the Firm&rsquo;s tolerance levels or where the need for remedial action is required in respect of identified weaknesses in the firm&rsquo;s mitigating controls.</p>
      <h2>Risks</h2>
      <p>Specific risks applicable to the Firm come under the headings of business, operational, credit and market risks.</p>
      <h2>Business risk</h2>
      <p>The Firm&rsquo;s revenue is reliant on the performance of the existing funds under management and its ability to launch new funds and obtain new mandates. As such, the risk posed to the Firm relates to underperformance resulting in a decline in revenue and adverse market conditions hindering the launch of new funds and ultimately the risk of redemptions from the funds managed by the firm and / or the termination of its investment advisory and asset management contracts. This risk is mitigated by:</p>
      <ul>
      <li>the closed-ended nature of funds under management with a fixed fund life;</li>
      <li>sufficient levels of capital held by the firm which will continue to cover the expenses of the business; and</li>
      <li>appropriate termination provisions included in investment advisory, asset management and portfolio management agreements.</li>
      </ul>
      <h2>Operational risk</h2>
      <p>The Firm places strong reliance on the operational procedures and controls that it has in place in order to mitigate risk and seeks to ensure that all personnel are aware of their responsibilities in this respect.</p>
      <p>The Firm has identified a number of key operational risks to manage. These relate to loss of key staff, loss of fund or large investor, underperformance, damage to reputation, failure to collect payments due, inability to meet payments or settle obligations. Appropriate polices are in place to mitigate against these risks, which includes taking out adequate professional indemnity insurance.</p>
      <h2>Credit risk</h2>
      <p>The Firm is exposed to credit risk in respect of its debtors, investment advisory and portfolio management fees invoiced and cash held on deposit.</p>
      <p>The Firm currently receives investment advisory, asset management and portfolio management fees and considers that there is little risk of default by its clients. All bank accounts are held with large international credit institutions.</p>
      <p>Given the nature of the Firm&rsquo;s exposures, no specific policy for hedging and mitigating credit risk is in place. The Firm uses the simplified standardised approach detailed in BIPRU 3.5.5 of the FCA Handbook when calculating risk weighted exposures of 1.6% (Cash at bank) and 8% in respect of its other assets.</p>
      <h2>Market risk</h2>
      <p>The Firm takes no market risk other than foreign exchange risk in respect of its accounts receivable and cash balances held in currencies other than GBP.</p>
      <p>Since the Firm takes no trading book positions on its balance sheet, the Firm&rsquo;s foreign exchange risk is limited to that which arises in respect of its accounts receivable and cash balances held in currencies other than GBP.</p>
      <p>The Firm calculates its foreign exchange risk by reference to the rules in BIPRU 7.5.1 of the FCA Handbook and applies an 8% risk factor to its foreign exchange exposure.</p>
      <h2>Liquidity risk</h2>
      <p>The Firm is required to maintain sufficient liquidity to ensure that there is no significant risk that its liabilities cannot be met as they fall due or to ensure that it can secure additional financial resources in the event of a stress scenario.</p>
      <p>The Firm retains an amount it considers suitable for providing sufficient liquidity to meet the working capital requirements under normal business conditions. The Firm has always had sufficient liquidity within the business to meet its obligations and there are no perceived threats to this given the cash deposits it holds. Additionally, it has historically been the case that all advisory fee debtors are settled promptly, thus ensuring further liquidity resources are available to the Firm on a timely basis. The cash position of the Firm is monitored under the supervision of the SMT on a monthly basis.</p>
      <h2>Regulatory capital </h2>
      <p>The Firm is a Limited Liability Partnership and its capital arrangements are established in its Partnership deed.&nbsp;</p>
      <p>The main features of the Firm&rsquo;s capital resources for regulatory purposes are as follows:</p>
      <table>
        <tbody>
          <tr>
            <td></td>
            <th>2021</th>
          </tr>
          <tr>
            <td></td>
            <th>£</th>
          </tr>
          <tr>
            <td>Tier 1 capital</td>
            <td>(145,000)</td>
          </tr>
          <tr>
            <td>Tier 2 capital</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Deductions from Tiers 1 and 2</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Total capital resources</td>
            <td><strong>(145,000)</strong></td>
          </tr>       
        </tbody>
      </table>
      <p>The Firm is small with a simple operational infrastructure. Its market risk is limited to foreign exchange risk on its accounts receivable in foreign currency, and credit risk from management and performance fees receivable from the funds under its management. The Firm follows the standardised approach to market risk and the simplified standard approach to credit risk.</p>
      <p>Limited License &ndash; In accordance with Article 97, the Firm is subject to the Fixed Overhead Requirement (&lsquo;<strong>FOR</strong>&rsquo;) and is not required to calculate an operational risk capital charge though it considers this as part of its process to identify the level of risk-based capital required.</p>
      <p>As discussed above the Firm is a limited licence firm and as such its capital requirements are the higher of:</p>
      <ul>
      <li>&euro;50,000; and</li>
      <li>The sum of the market and credit risk requirements; or</li>
      <li>The FOR which is essentially 25% of the Firm&rsquo;s operating expenses less certain variable costs.</li>
      </ul>
      <p>The FOR is calculated in accordance with FCA rules, based on the Firm&rsquo;s previous years audited expenditure. The Firm has adopted the standardised approach to credit and market risk and the above figures have been produced on that basis.The Firm is not subject to an operational risk requirement.</p>
      <h2>Capital requirement</h2>
      <p>The Firm&rsquo;s Pillar 1 capital requirement has been determined by reference to the Firm&rsquo;s FOR and calculated in accordance with the FCA&rsquo;s General Prudential Sourcebook (&rsquo;<strong>GENPRU</strong>&rsquo;) at GENPRU 2.1.53. The requirement is based on the FOR since this exceeds the total of the credit and market risk capital requirements it faces and also exceeds its base capital requirement of &euro;50,000.</p>
      <p>The FOR for the year ended 31 March 2021 was based on projected overheads and was calculated as being &pound;142,290. The regulatory capital of the Firm at 31 March 2021 was higher than the FOR, as such the Firm had a surplus in meeting its capital requirement of &pound;2,710 at the year-end.&nbsp;.</p>
      <p>The Firm monitors its expenditure on a monthly basis and takes into account any material fluctuations in order to determine whether the FOR remains appropriate to the size and nature of the business or whether any adjustment needs to be made intra-year.</p>
      <p>This is monitored by the Chief Operating Officer and the Compliance Officer and reported to the SMT on a monthly basis.</p>
      <h2>UK Financial Reporting Council&rsquo;s Stewardship Code</h2>
      <p>FCA COBS Rule 2.2.3R requires FCA authorised firms to disclose whether they conform to the requirements of the UK Financial Reporting Council&rsquo;s Stewardship Code (&lsquo;<strong>the</strong><strong>Code</strong>&rsquo;). Adherence to the Code is voluntary. The Firm carries on investment advisory, real estate asset management and portfolio management activities concerning real estate investments in the hospitality industry. Therefore, while the Firm supports the principles of the Code, it does not consider it appropriate to conform to the Code at this time.</p>
      <h2>Remuneration disclosure</h2>
      <p>The Firm is authorised and regulated by the Financial Conduct Authority as a BIPRU Firm and, so, it is subject to FCA Rules on remuneration. These are contained in the FCA's Remuneration Code located in the SYSC Sourcebook of the FCA&rsquo;s Handbook.</p>
      <p>The Remuneration Code (&lsquo;<strong>the RemCode</strong>&rsquo;) cover(s) an individual&rsquo;s total remuneration, fixed and variable. The Firm incentivises staff through a combination of the two.</p>
      <p>The Firm's business is to provide investment management, advisory and real estate asset management services.</p>
      <p>The Firm&rsquo;s policy is designed to ensure that it complies with the RemCode and its compensation arrangements:</p>
      <ul>
      <li>are consistent with and promote sound and effective risk management;</li>
      <li>do not encourage excessive risk taking;</li>
      <li>include measures to avoid conflicts of interest; and</li>
      <li>are in line with the Firm's business strategy, objectives, values and long-term interests.</li>
      </ul>
      <h2>Proportionality</h2>
      <p>Enshrined in the UK remuneration provisions is the principle of proportionality. The FCA has sought to apply proportionality in the first instance by instituting two tests. Firstly, a firm that is significant in terms of its size must disclose quantitative information referred to in BIPRU 11.5.18R at the level of senior personnel. Secondly, that a firm must make disclosure that is appropriate to the size, internal organisation and the nature, scope and complexity of their activities.&nbsp;</p>
      <p>The Firm is not &lsquo;significant&rsquo; and so makes this disclosure in accordance with the second test (BIPRU 11.5.20R(2)).</p>
      <h2>Application of the requirements</h2>
      <p>The Firm is required to disclose certain information on at least an annual basis regarding its remuneration policy and practices for those staff whose professional activities have a material impact on the risk profile of the Firm. The Firm&rsquo;s disclosure is made in accordance with its size, internal organisation and the nature, scope and complexity of its activities.</p>
      <p><strong>Summary of information on the decision-making process used for determining the Firm&rsquo;s remuneration policy including use of external benchmarking consultants where relevant:</strong></p>
      <ul>
      <li>The Firm&rsquo;s remuneration policy has been agreed by the SMT in line with the remuneration principles set out by the FCA.</li>
      <li>Due to the size, nature and complexity of the Firm, it is not required to appoint an independent remuneration committee.</li>
      <li>The Firm&rsquo;s policy will be reviewed as part of the wider annual review of its processes and procedures or following a significant change to the business requiring an update to its internal capital adequacy assessment.</li>
      <li>The Firm&rsquo;s ability to pay bonuses is based on the performance of the Firm overall.</li>
      </ul>
      <p><strong>Summary of how the Firm links between pay and performance:</strong></p>
      <ul>
      <li>Individuals are rewarded based on their contribution to the overall strategy of the business.&nbsp;</li>
      <li>Other factors such as performance, reliability, effectiveness of controls, business development and contribution to the business are considered when assessing the performance of the senior staff responsible for the infrastructure of the Firm.</li>
      </ul>
      <p>The Firm may omit required disclosures where it believes that the information could be regarded as prejudicial to the UK or other national transposition of Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection of individuals with regard to the processing of personal data and on the free movement of such data. We have made no omissions on the grounds of data protection.</p>
      <p>Disclosure of the aggregate remuneration for staff permits firms to take account of the provisions of the Data Protection Directive (Directive 95/46/EC) regarding the protection of individuals in relation to the processing of personal data. Due to the low number of staff assessed as Code Staff for the Firm and their remuneration arrangements, the members have relied upon BIPRU 11.5.20R(2) and determined that aggregate quantitative disclosure for disclosure for Code Staff is inappropriate.</p>
      </section>

    </article>
    </MotionConfig>
  )
}

export default PillarPage
